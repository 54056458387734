.seed-phrase-verification-container .seed-phrase-input-container {
  position: relative;
}

.seed-phrase-verification-container .seed-phrase-index {
  position: absolute;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 0.25rem;
}
