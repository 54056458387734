.seed-phrase-preview-container .seed-phrase-container {
  filter: blur(3px);
  transition: filter 0.2s;
  will-change: filter;
  text-align: left;
}

.seed-phrase-preview-container:hover .seed-phrase-container {
  filter: none;
}

.seed-phrase-preview-container .seed-phrase-index {
  padding-right: 0.25rem;
}
